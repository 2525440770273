import {ProductModal} from "../../product/modal/product-modal";
import {PHModal} from "../../ph/modal/phmodal";
import {Puritymodal} from "../../purity/modal/puritymodal";
import {SolubilityModal} from "../../solubility/modal/solubility-modal";
import {MoistureModal} from "../../moisture/modal/moisture-modal";
import {SalesOrderQualityParamDetailsModel} from "./sales-order-quality-param-details.model";
import {PackingModel} from '../../packing/model/packing-model';
import {PackingDetailModel} from '../../packing/model/packing-detail-model';

export class SoDetailModal {
    item: ProductModal;
    ph: PHModal;
    moisture: MoistureModal;
    solubility: SolubilityModal;
    purity: Puritymodal;
    price: number;
    weight: number;
    packingType: number;
    packingCapacityDetails: number;
    packedQuantity: number;
    salesOrderQualityParamDetails: Array<SalesOrderQualityParamDetailsModel>;

    // Angular Use [Dhairya]
    selectedPacking: PackingModel;
    selectedPackageCapacity: PackingDetailModel;
}
