import { Injectable } from '@angular/core';
import {BaseService} from '../../../core/service/base-service';
import {HttpClient} from '@angular/common/http';
import {AuthUtilsService} from '../../../core/util/auth-utils.service';
import { AdvocateModel } from '../model/advocate-model';

@Injectable({
  providedIn: 'root'
})
export class AdvocateService extends BaseService<AdvocateModel> {

  constructor(
      http: HttpClient,
      authService: AuthUtilsService
  ) {
    super(http, authService);
  }

  getModuleName(): string {
    return 'nbfc/advocate';
  }

  validate(): boolean {
    return false;
  }
}
