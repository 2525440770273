import {Puritymodal} from "../../purity/modal/puritymodal";
import {MoistureModal} from "../../moisture/modal/moisture-modal";
import {PHModal} from "../../ph/modal/phmodal";
import {SolubilityModal} from "../../solubility/modal/solubility-modal";
import {ProductModal} from "../../product/modal/product-modal";

export class PreInwardDetailModal {
    id: number;
    item:ProductModal;
    vehicleDetails: string;
    price: number;
    weight: number;
    total : number;
    totalPrice: number;
    purity:	Puritymodal;
    moisture: MoistureModal;
    ph:	PHModal;
    solubility: SolubilityModal;
    sumTotal : number;
    vendorCode: string;
}
