import {UomModal} from "../../../core/modal/uom-modal";
import {ProductDetailModal} from "./product-detail-modal";
import { ProcessModel } from '../../process/model/process.model';
import {ProductTypeModel} from "../../product-type/model/product-type.model";
import {CustomParametersDetailsModel} from "../../../shared/custom-param-detail/model/custom-parameters-details-model";
import {PartCodeDetailModel} from '../../part-code/model/part-code-detail-model';
import {DocumentDetailsModel} from '../../bom/models/document-details-model';
import {UomConstModal} from "../../../core/modal/uom-const-modal";

export class ProductModal {

    id: number;
    description: string;
    name: string;
    physicalProperty: string;
    productCode: string;
    uom: UomModal;
    secondaryUom: UomConstModal;
    isWater = false;
    inputProcessList: Array<ProcessModel>;
    outputProcessList: Array<ProcessModel>;
    itemDetails: Array<ProductDetailModal>;
    productType: Array<ProductTypeModel>;
    finishProductList: Array<ProductModal>;
    isSystemGenerated = false;
    itemCodeConfigDetails: PartCodeDetailModel[];
    itemDetailsCustomParam: Array<CustomParametersDetailsModel>;
    documentDetails: Array<DocumentDetailsModel>;
    minQuantity: number;


    //Angular Use Only
    uomName: string;
    secondaryUomName : string;
    file: File[];
}
