import {MoistureModal} from "../../moisture/modal/moisture-modal";
import {PHModal} from "../../ph/modal/phmodal";
import {Puritymodal} from "../../purity/modal/puritymodal";
import {SolubilityModal} from "../../solubility/modal/solubility-modal";
import {ProductModal} from "../../product/modal/product-modal";

export class InwardDetailModal {
    id: number;
    isPass = true;
    partyMoisture: MoistureModal;
    partyPh: PHModal;
    partyPrice:	number;
    partyPurity: Puritymodal;
    partySolubility: SolubilityModal;
    partyWeight: number;
    productName: ProductModal;
    receivedMoisture: MoistureModal;
    receivedPh: PHModal;
    receivedPrice: number;
    receivedPurity:	Puritymodal;
    receivedSolubility:	SolubilityModal;
    receivedWeight:	number;
    totalPrice:	number;
    vendorCode: string;
}
