import { Injectable } from '@angular/core';
import {BaseService} from "../../../core/service/base-service";
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../../core/util/auth-utils.service";
import {PurchaseOrderModal} from "../modal/purchase-order";
import {Observable} from "rxjs";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {environment} from "../../../../environments/environment";
import {Organization} from "../modal/organization";
import {MaterialRequestModel} from "../../material-request/model/material-request.model";

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService extends BaseService<PurchaseOrderModal> {

  constructor(
      http: HttpClient,
      authService: AuthUtilsService
  ) {
    super(http,authService);
  }

  getModuleName(): string {
    return "chemical/purchaseOrder";
  }

  validate(): boolean {
    return false;
  }

  public getOrganizationList(): Observable<ResponseData<Organization>> {
    return this.http.get<ResponseData<Organization>>(environment.SERVER_URL + 'organization');
  }

  getRequestsList(): Observable<ResponseData<MaterialRequestModel>> {
    return this.http.get<ResponseData<MaterialRequestModel>>(environment.SERVER_URL + 'chemical/requestApproval/getRecordsByPO');
  }
}

