import {ProcessModel} from "../../process/model/process.model";
import {ProductionBatchDetailsCostHeadModel} from "./production-batch-details-cost-head.model";
import {ProductionBatchDetailsInputModel} from "./production-batch-details-input.model";
import {ProductionBatchDetailsOutputModel} from "./production-batch-details-output.model";
import {PartyModal} from "../../party/modal/party-modal";
import {SalesOrderModal} from "../../sales-order/modal/sales-order-modal";
import {MachineModal} from "../../machine/modal/machine-modal";
import {ProductionBatchDetailsOtherProduct} from "./production-batch-details-other-product";
import {DynamicDataModel} from "../../../shared/dynamic-form/model/dynamic-data-model";
import {ProductionBatchPlanningResponseModel} from "./production-batch-planning-response.model";
import {PlanningModel} from "../../planning/model/planning.model";

export class ProductionBatchModel {
    id: number;
    startDate: Date;
    endDate: Date;
    pbNumber: string;
    process: ProcessModel;
    handingLoss: number;
    totalCost: number;
    totalWeight: number;
    outputInPer: number;
    party: PartyModal;
    salesOrder: SalesOrderModal;
    machine:MachineModal;
    planning: PlanningModel;
    productionBatchDetailsCostHead: Array<ProductionBatchDetailsCostHeadModel>;
    productionBatchDetailsInput: Array<ProductionBatchDetailsInputModel>;
    productionBatchDetailsOutput: Array<ProductionBatchDetailsOutputModel>;
    productionBatchDetailsOtherProduct: Array<ProductionBatchDetailsOtherProduct>;
    moduleCustomFieldValues: Array<DynamicDataModel>;
    effectivePrice: number;


    //For Angular Use, DO NOT DELETE IT
    totalCostHead: number;
    totalInputWeight: number;
    totalInputPrice: number;
    totalOutputWeight: number;
    totalOtherProductWeight: number;
    totalOtherProductCost: number;

    sdString: string;
    edString: string;
    handingLossString: string;
    outputInPerString: string;
    totalWeightString: string;
    totalCostString: string;
}
