import {PackingModel} from './packing-model';

export class PackingDetailModel {
    id: number;
    capacity: number;
    packingUOM: PackingModel;
    packingUOMCapacity: number;
    isDeleted = false;

    // Angular Use [Dhairya]
    packingProductType: any;
    capacityList: Array<PackingDetailModel>;
    subPackingCapacity: PackingDetailModel;
}
