export const UomConstant = [
    {
        id: 1,
        nameKey: 'GM',
        name: 'GM',
    },
    {
        id: 2,
        nameKey: 'KG',
        name: 'KG',
    },
    {
        id: 3,
        nameKey: 'LTR',
        name: 'LTR'
    },
    {
        id: 4,
        nameKey: 'METER',
        name: 'METER'
    },
    {
        id: 5,
        nameKey: 'TON',
        name: 'TON'
    },
    {
        id: 6,
        nameKey: 'NUMBER',
        name: 'NUMBER'
    },
    {
        id: 7,
        nameKey: 'SET',
        name: 'SET'
    },
    {
        id: 8,
        nameKey: 'BAG',
        name: 'BAG'
    },
    {
        id: 9,
        nameKey: 'ML',
        name: 'ML'
    }
];
