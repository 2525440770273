import {ProductModal} from '../../product/modal/product-modal';

export class DocumentDetailsModel {
    id: number;
    referencedDocument: string;
    fileName: string;
    uploadedFileName: string;
    material: ProductModal;
    module: string;
    // Angular use Don't delete dhairya
    file: File;
    moduleName = 'doc';

    imgUrl: any;
}
