import {UomModal} from '../../../core/modal/uom-modal';
import {PackingDetailModel} from './packing-detail-model';
import {PartnerModel} from '../../user/modal/partner-model';

export class PackingModel {
    id: number;
    packingType: string;
    uom: UomModal;
    capacityDetails: Array<PackingDetailModel>;
    partner: PartnerModel;
    isDeleted = false;

    // Angular Use [Dhairya]
    uomName: any;
}
