import { Injectable } from '@angular/core';
import {BaseService} from "../../../core/service/base-service";
import {MaterialRequestConfigModel} from "../model/material-request-config.model";
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../../core/util/auth-utils.service";
import {Observable} from "rxjs";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialRequestConfigService extends BaseService<MaterialRequestConfigModel>{

  constructor( http: HttpClient,
               authService: AuthUtilsService
  ) {
    super(http, authService);
  }

  getModuleName(): string {
    return "chemical/requestApprovalConfig";
  }

  validate(): boolean {
    return false;
  }

  fetchConfig(): Observable<ResponseData<MaterialRequestConfigModel>> {
    return this.http.get<ResponseData<MaterialRequestConfigModel>>(environment.SERVER_URL + this.getModuleName() + '/getApprovalConfig');
  }

  saveConfig(config: MaterialRequestConfigModel): Observable<ResponseData<MaterialRequestConfigModel>> {
    return this.http.post<ResponseData<MaterialRequestConfigModel>>(environment.SERVER_URL + this.getModuleName() + '/save', config);
  }

  updateConfig(config: MaterialRequestConfigModel): Observable<ResponseData<MaterialRequestConfigModel>> {
    return this.http.patch<ResponseData<MaterialRequestConfigModel>>(environment.SERVER_URL + this.getModuleName() + '/update', config);
  }
}
