import {InwardDetailModal} from "./inward-detail-modal";
import {PartyModal} from "../../party/modal/party-modal";
import {PurchaseOrderModal} from "../../purchase-order/modal/purchase-order";
import {PreInwardModal} from "../../pre-inward/modal/pre-inward-modal";
import { InwardDetailsCostHead } from './inward-details-cost-head';
import {ProductTypeModel} from "../../product-type/model/product-type.model";

export class InwardModal {
    id: number;
    date: Date; 
    invoiceNumber: string;
    party: PartyModal;
    po:	PurchaseOrderModal;
    preInwardId: PreInwardModal;
    remark:	string;
    totalAmount: number;
    totalWeight: number;
    slip: string;
    inwardDetailsCostHead: Array<InwardDetailsCostHead>;
    details: Array<InwardDetailModal>;
    
    //For Angular Use, DO NOT DELETE IT
    totalCostHead: number;
    partyTotalPrice: number;
    partyTotalWeight: number;
    receivedTotalWeight: number;
    receivedTotalPrice: number;
    partyTotalAmount: number;
    receivedTotalAmount: number;
    dateString: string;
    poString: string;
    partyString: string;
    totalAmountString: string;
    totalWeightString: string;

    productType: Array<ProductTypeModel>;
    totalInwardAmount : number;
}
