import {PartyModal} from "../../party/modal/party-modal";
import {ProductModal} from "../../product/modal/product-modal";
import {Puritymodal} from "../../purity/modal/puritymodal";
import {PHModal} from "../../ph/modal/phmodal";
import {SolubilityModal} from "../../solubility/modal/solubility-modal";
import {MoistureModal} from "../../moisture/modal/moisture-modal";

export class VendorCodeRequestResponseModel {
  party: PartyModal;
  item: ProductModal;
  purity: Puritymodal;
  ph: PHModal;
  solubility: SolubilityModal;
  moisture: MoistureModal;
  vendorCode: string;
}
